<template>
  <div class="form">
    <section class="form-section">
      <FormRow :required="true">
        <template v-slot:label>ユーザー名</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="form-group">
              <div class="form-group-item">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors['nameSei'] }"
                  type="text"
                  name="nameSei"
                  v-trim
                  v-maxlength
                  maxlength="100"
                  placeholder="姓"
                  v-model="formData.nameSei"
                />
              </div>
              <div class="form-group-item">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors['nameMei'] }"
                  type="text"
                  name="nameMei"
                  v-trim
                  v-maxlength
                  maxlength="100"
                  placeholder="名"
                  v-model="formData.nameMei"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>メールアドレス / ID</template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <input
                  class="form-control"
                  :class="{ 'is-error': errors['email'] }"
                  type="text"
                  name="email"
                  v-email
                  v-trim
                  v-maxlength
                  maxlength="50"
                  v-model="formData.email"
                />
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <FormRow :required="true">
        <template v-slot:label>初回パスワード</template>
        <template v-slot:labelNote><span class="text-note">（8文字以上で半角英数字および記号（/ \ ¥ を除く）を全て組み合わせ）</span></template>
        <template v-slot:content>
          <div class="form-content-row">
            <div class="row">
              <div class="col-6">
                <div class="form-password">
                  <input
                    class="form-control form-password-input"
                    :class="{ 'is-error': errors['password'] }"
                    :type="pwdVisible ? 'text' : 'password'"
                    name="password"
                    v-trim
                    v-model="formData.password"
                  />
                  <label class="form-check form-password-toggle">
                    <input class="form-check-input" type="checkbox" value="" v-model="pwdVisible" />
                    <i class="aikon form-password-icon"></i>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </template>
      </FormRow>
      <UserMailSetting :formData="formData" @updateData="updateData"/>
    </section>
  </div>
</template>

<script>
import { required, email, maxLength } from 'vuelidate/lib/validators';
import password from '@/mixins/plugin/password';
import error from '@/mixins/plugin/error';
import { isAlphanumSpecialWithMinLength } from '@/helpers/validators';

//component
import FormRow from '@/components/FormRow.vue';
import UserMailSetting from '@/components/UserMailSetting.vue';
import userMailSetting from '@/mixins/module/userMailSetting';
//constant
import { RoleConstants } from '@/constants/role';

export default {
  name: 'SettingUserRegisterMaster',
  data: function() {
    return {
      formData: {
        nameSei: '',
        nameMei: '',
        email: '',
        password: '',
        receptionEmailFlag: 1,
        authEmailFlag: 0,
      },
    };
  },
  validations() {
    const formData = {
      nameSei: { required, maxLength: maxLength(100) },
      nameMei: { required, maxLength: maxLength(100) },
      email: { required, email, maxLength: maxLength(50) },
      password: { required, alphanumSpecialWithMinLength: isAlphanumSpecialWithMinLength(8) },
    };
    return { formData };
  },
  mixins: [password, error, userMailSetting],
  components: {
    FormRow,
    UserMailSetting
  },
  methods: {
    async register() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.getErrors(this.$v.formData, 'user');
      } else {
        let data = {
          ...this.formData,
          receptionEmailFlag: this.formData.receptionEmailFlag ? 1 : 0,
          authEmailFlag: this.formData.authEmailFlag ? 1 : 0,
        };
        this.errors = {};
        const result = await this.$store.dispatch('settingUser/createUser', { data: data, kind: RoleConstants.ROLE_GMO });
        if (result) {
          await this.$router.push({ name: 'SettingUser' });
          this.$message.showSuccess('createdUserInfoSuccess');
        }
      }
    },
  },
  mounted: function() {
    this.$emit('footer-show');
  },
};
</script>
